body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  color: white;
  background: rgba(16,16,16,1);
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

.logo {
  width: 140px;
}

@media only screen and (min-width: 768px) {
  .logo {
    width: 160px;
  }
}

/* HEADER */

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(16,16,16,1);
  overflow-x: hidden;
}

.header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 16px 24px;
  justify-content: space-around;
}

@media only screen and (min-width: 768px) {
  .header-container {
    width: 768px;
  }
}

@media only screen and (min-width: 1152px) {
  .header-container {
    width: 1152px;
  }
}

/* CONTENT */

.content {
  position: relative;
  z-index: 0;
  background: rgba(24,24,24,1);
  overflow-x: hidden;
  padding: 24px 0;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: 100%;
  box-sizing: border-box;
  padding: 16px 24px;
}

.cell {
  background: rgba(120,120,124,0.16);
  box-sizing: border-box;
  padding: 20px;
  border-radius: 16px;
}

@media only screen and (min-width: 768px) {
  .content {
    padding: 48px 0;
  }

  .container {
    width: 768px;
  }

  .cell {
    padding: 24px;
    border-radius: 20px;
  }
}

@media only screen and (min-width: 1152px) {
  .container {
    width: 1152px;
  }
}

/* FOOTER */

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgba(16,16,16,1);
  overflow-x: hidden;
}

.footer-container {
  width: 100%;
  box-sizing: border-box;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  gap: 16px;
}

.footer-menu {
  display: flex;
  gap: 16px;
}

.footer-menu a {
  text-decoration: none;
  color: rgba(162,164,168,1);
}

.footer-menu a:hover {
  color: white;
}

@media only screen and (min-width: 768px) {
  .footer-container {
    width: 768px;
    font-size: 14px;
    flex-direction: row;
  }
}

@media only screen and (min-width: 1152px) {
  .footer-container {
    width: 1152px;
  }
}

.ta-c {
  text-align: center;
}

/* TERMS */

.legal-cell {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}

.legal-cell-date {
  font-size: 12px;
  margin-bottom: 6px;
  font-weight: 600;
  color: rgba(100,102,108,1);
}

@media only screen and (min-width: 768px) {
  .legal-cell-date {
    font-size: 14px;
  }
}

.terms {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.terms-cell {
  display: flex;
  gap: 12px;
  margin-top: 16px;
  font-weight: 400;
  color: rgba(208,210,216,1)
}

.terms h2 {
  margin-bottom: 16px;
}

.terms-number {
  min-width: 18px;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  font-size: 9px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(130,132,138,0.16);
  color: rgba(162,164,168,1);
  margin-top: 2px;
}

@media only screen and (min-width: 768px) {
  .terms-number {
    min-width: 20px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    font-size: 10px;
  }
}

/* PRIVACY */

.services-table {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  line-height: 20px;
  font-size: 14px;
}

@media only screen and (min-width: 768px) {
  .services-table {
    line-height: 24px;
    font-size: 16px;
  }
}

.services-table-heading {
  font-weight: 600;
}

.services-table-cell a {
  color: rgba(56,112,255,1);
}

.services-table-left {
  float: left;
  width: 20%;
  box-sizing: border-box;
  padding-right: 12px;
}

.services-table-center {
  float: left;
  width: 40%;
  box-sizing: border-box;
  padding-right: 12px;
  overflow: hidden;
}

.services-table-right {
  float: left;
  width: 40%;
  box-sizing: border-box;
  padding-right: 12px;
}

.privacy {
  display: grid;
  flex-direction: row;
  gap: 48px;
}

.privacy p {
  margin-top: 16px;
}

.divider-primary {
  height: 2px;
  width: 100%;
  border-radius: 1px;
  background-color: rgba(130,132,138,0.32);
  margin: 16px 0;
}

.divider-secondary {
  height: 2px;
  width: 100%;
  border-radius: 1px;
  background-color: rgba(130,132,138,0.16);
  margin: 16px 0;
}

/* START */

p {
  font-size: 15px;
  line-height: 24px;
  color: rgba(204,208,216,1);
}

h2 {
  font-size: 20px;
}

h1 {
  font-size: 28px;
}

@media only screen and (min-width: 768px) {
  p {
    font-size: 16px;
    line-height: 24px;
  }
  
  h2 {
    font-size: 24px;
  }

  h1 {
    font-size: 32px;
  }
}

/* TEXTFIELD */

.textfield {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.textfield-title {
  font-size: 12px;
  font-weight: 700;
}

.textfield-input input {
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  background: rgba(130,132,138,0.16);
  border: none;
  color: white;
  border-radius: 12px;
  padding: 0 16px;
  font-size: 16px;
}

.textfield-input input:focus {
  outline: none;
}

/* BUTTON */

.button {
  box-sizing: border-box;
  position: relative;
}

.button-top {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  color: white;
}

/* PASSWORD RESET */

.password-reset-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.password-reset-form {
  display: flex;
  flex-direction: column;
  gap: 32px;
  box-sizing: border-box;

}

@media only screen and (min-width: 768px) {
  .password-reset-form {
    width: calc(768px * 0.8);
  }
}

/* REQUIREMENT */

.requirements {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: -12px;
  margin-bottom: 8px;
}

.requirement {
  display: flex;
  flex-direction: row;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
}

.requirement-checkbox-checked {
  background: rgba(130,132,138,0.16);;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  min-width: 20px;
}

.requirement-checkbox-checked img {
  width: 30px;
  height: 30px;
  transform: translateX(-5px) translateY(-5px);
}

.requirement-checkbox {
  background: rgba(130,132,138,0.16);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  min-width: 20px;
}

/* START */

.start-app {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
}

.start-app-left img {
  width: 150px;
  height: 50px;
  margin-top: 8px;
  cursor: pointer;
}

.start-app-left {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  text-align: center;
}

.start-app-right {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
}

.start-app-right img {
  width: 60%;
}

@media only screen and (min-width: 768px) {
  .start-app {
    flex-direction: row;
    height: calc(100vh - 224px);
  }

  .start-app-left {
    width: 50%;
    padding-right: 32px;
    align-items: flex-start;
    text-align: left;
  }

  .start-app-right {
    width: 50%;
    margin-top: 0;
  }

  .start-app-right img {
    width: 68%;
  }
}

@media only screen and (min-width: 1152px) {
  .start-app-right img {
    width: 52%;
  }
}